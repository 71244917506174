import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "g-row g-row--align_center g-row--appearance_spaced"
}
const _hoisted_2 = {
  key: 0,
  class: "g-cell g-cols g-cols--12-xs g-cols--narrow-mac"
}
const _hoisted_3 = { class: "pagination" }
const _hoisted_4 = { class: "g-row g-row--align_center" }
const _hoisted_5 = { class: "g-cell g-cols g-cols--auto" }
const _hoisted_6 = { class: "g-row g-row--align_center g-row--justify_between g-row--justify_start-sm" }
const _hoisted_7 = { class: "g-cell" }
const _hoisted_8 = { class: "ds-caption ds-caption--size_xs" }
const _hoisted_9 = { class: "g-cell" }
const _hoisted_10 = { class: "g-cell is-visible-sm" }
const _hoisted_11 = { class: "ds-caption ds-caption--size_xs" }
const _hoisted_12 = { class: "g-cell g-cols g-cols--12-xs g-cols--auto-mac" }
const _hoisted_13 = { class: "pagination__icon" }
const _hoisted_14 = { class: "pagination__content" }
const _hoisted_15 = {
  key: 1,
  class: "pagination__item is-disabled"
}
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "pagination__item is-active" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = {
  key: 2,
  class: "pagination__item is-disabled"
}
const _hoisted_20 = { class: "pagination__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_select = _resolveComponent("control-select")!
  const _component_icon_arrow_left = _resolveComponent("icon-arrow-left")!
  const _component_icon_arrow_right = _resolveComponent("icon-arrow-right")!

  return ($setup.page)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($setup.props.needAddBtns)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("Об'єктів на сторінці")), 1)
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_control_select, {
                          style: {"width":"100px"},
                          "allow-empty": false,
                          options: $setup.pageSizes,
                          "open-direction": "auto",
                          modelValue: $setup.pagelimit,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.pagelimit) = $event)),
                          onInput: $setup.changeLimit
                        }, null, 8, ["options", "modelValue"])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('Показано')) + " " + _toDisplayString($setup.pageObj.from || 0) + " " + _toDisplayString(_ctx.$t('до')) + " " + _toDisplayString($setup.page.count < $setup.pageObj.to ? $setup.page.count || 0 : $setup.pageObj.to || 0) + " " + _toDisplayString(_ctx.$t('з')) + " " + _toDisplayString($setup.page.count) + " " + _toDisplayString(_ctx.$t("об'єктів")), 1)
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_12, [
          ($setup.page && $setup.range.max > 1)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["pagination", { 'pagination--variant_1': $setup.props.needAddBtns }])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["pagination__item pagination__item--prev", { 'is-disabled': !$setup.page.hasPrevious() }]),
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.previous()), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_icon_arrow_left)
                  ])
                ], 2),
                _createElementVNode("div", _hoisted_14, [
                  ($setup.page.hasPrevious())
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "pagination__item",
                        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.select($setup.range.min)), ["prevent"]))
                      }, _toDisplayString($setup.range.min), 1))
                    : _createCommentVNode("", true),
                  ($setup.gaps.left)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, "..."))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fills.left, (page) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "pagination__item",
                      key: page,
                      onClick: _withModifiers(($event: any) => ($setup.select(page)), ["prevent"])
                    }, _toDisplayString(page), 9, _hoisted_16))
                  }), 128)),
                  _createElementVNode("div", _hoisted_17, _toDisplayString($setup.page.getPageNumber()), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fills.right, (page) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "pagination__item",
                      key: page,
                      onClick: _withModifiers(($event: any) => ($setup.select(page)), ["prevent"])
                    }, _toDisplayString(page), 9, _hoisted_18))
                  }), 128)),
                  ($setup.gaps.right)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, "..."))
                    : _createCommentVNode("", true),
                  ($setup.page.hasNext())
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 3,
                        class: "pagination__item",
                        onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => ($setup.select($setup.range.max)), ["prevent"]))
                      }, _toDisplayString($setup.range.max), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["pagination__item pagination__item--next", { 'is-disabled': !$setup.page.hasNext() }]),
                  onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => ($setup.next()), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_20, [
                    _createVNode(_component_icon_arrow_right)
                  ])
                ], 2)
              ], 2))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}