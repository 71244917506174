import { phoneCodeListResource } from '@services/authenticate.service'

interface Result {
  getCodes: Promise<void>;
}

export default function (): Promise<Result> | any {
  const getCodes = async (): Promise<void> => {

    const params = { limit: 500 }
    const res = await phoneCodeListResource.execute(params)

    const { data: { items } } = res

    return items
  }

  return { getCodes }
}