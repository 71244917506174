import {
  createResource,
  postResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'
import { simpleTransformer } from '@resource/transformer'

const MODEL_PREFIX = 'auth'

const LOGIN = prefixAPI('/login/', MODEL_PREFIX)
const REGISTER = prefixAPI('/register/', MODEL_PREFIX)
const LOGOUT = prefixAPI('/logout/', MODEL_PREFIX)
const PASSWORD_CHANGE = prefixAPI('/password/change/', MODEL_PREFIX)
const PASSWORD_RESET = prefixAPI('/password/set/', MODEL_PREFIX)
const PHONE_CODE_LIST = prefixAPI('/phone-code/list/{?limit}', MODEL_PREFIX)

export const loginResource = createResource(LOGIN, postResource)
export const registerResource = createResource(REGISTER, postResource)
export const logoutResource = createResource(LOGOUT, postResource, simpleTransformer)
export const passwordChangeResource = createResource(PASSWORD_CHANGE, postResource)
export const passwordResetResource = createResource(PASSWORD_RESET, postResource)
export const phoneCodeListResource = createResource(PHONE_CODE_LIST)
