import moment from 'moment'
import useGlobal from '@composables/useGlobal'
import { simplePluralize } from '@utils/pluralize'

export default function (): any {
  const { $t } = useGlobal()

  const daysVariants = [
    $t('день'),
    $t('дні'),
    $t('днів'),
  ]
  
  const leftVariants = [
    $t('Залишився'),
    $t('Залишилось'),
    $t('Залишилось'),
  ]
  
  const getTimeLeft = (item: any) => {
    const { start_date, end_date, days_left, total_day_number } = item
    const start = moment(start_date)
    const end = moment(end_date)
    const leftText = simplePluralize(days_left, leftVariants)
    const daysText = simplePluralize(days_left, daysVariants)
    const percent = 100 - (days_left / total_day_number * 100)
    const preparedPercent = percent > 100 ? 100 : (percent < 0 ? 0 : percent)
  
    return {
      days: days_left,
      daysText,
      leftText,
      percent: preparedPercent,
      start: start.format('DD.MM.YYYY'),
      end: end.format('DD.MM.YYYY'),
    }
  }

  return { getTimeLeft }
}