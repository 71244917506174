import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow_top = _resolveComponent("icon-arrow-top")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["scroll-top", { 'is-active': $setup.isVisible }]),
    onClick: _withModifiers($setup.scrollTop, ["prevent"])
  }, [
    _createVNode(_component_icon_arrow_top)
  ], 10, _hoisted_1))
}