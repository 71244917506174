import useGlobal from '@/composables/useGlobal'

export default function () {
  const { $t } = useGlobal()

  const items = [
    {
      title: $t("Профільні дані"),
      to: 'profile:settings',
    },
    {
      title: $t("Мої об'єкти страхування"),
      to: 'profile:insurance-objects:list',
    },
    {
      title: $t("Мої поліси"),
      to: 'profile:policies:list',
    },
    {
      title: $t("Мої юридичні особи"),
      to: 'profile:legal-entity:list',
    },
    {
      title: $t("Мої страхові випадки"),
      to: 'profile:insurance-cases:list',
    },
  ]

  return { items }
}