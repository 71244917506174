import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "error-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_VeeField = _resolveComponent("VeeField")!
  const _component_VeeError = _resolveComponent("VeeError")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-control-descriptor", { error: $props.errors[$props.name] }])
  }, [
    ($props.inputLabel)
      ? (_openBlock(), _createBlock(_component_InputLabel, _mergeProps({
          key: 0,
          "input-label": $props.inputLabel,
          "is-required": $setup.isRequired
        }, _ctx.$attrs), null, 16, ["input-label", "is-required"]))
      : _createCommentVNode("", true),
    ($props.prependIcon)
      ? (_openBlock(), _createBlock($setup["PrependComponent"], {
          key: 1,
          "prepend-icon": $props.prependIcon
        }, null, 8, ["prepend-icon"]))
      : _createCommentVNode("", true),
    _createVNode(_component_VeeField, _mergeProps({
      class: [{ error: $props.errors[$props.name] }, $props.inputClass],
      as: $props.component,
      name: $props.name,
      options: $props.options,
      type: $props.type,
      "option-label": $props.optionLabel,
      "track-by": $props.trackBy,
      rules: $props.rules,
      slots: _ctx.$slots
    }, _ctx.$attrs, { "onUpdate:modelValue": $setup.updateValue }), null, 16, ["class", "as", "name", "options", "type", "option-label", "track-by", "rules", "slots"]),
    _createVNode(_component_VeeError, { name: $props.name }, {
      default: _withCtx(({ message }) => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(message), 1)
      ]),
      _: 1
    }, 8, ["name"])
  ], 2))
}