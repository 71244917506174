import Select from './widgets/Select.vue'
import Datepicker from './widgets/Datepicker.vue'
import Autocomplete from './widgets/Autocomplete.vue'

export const WIDGETS_MAP = {
  'multiselect': {
    component: Select,
    config: {
      multiple: true,
    },
  },
  'select': {
    component: Select,
    config: {
      multiple: false,
    },
  },
  'datepicker_range': {
    component: Datepicker,
    config: {
      range: true,
    },
  },
  'autocomplete': {
    component: Autocomplete,
  },
}
