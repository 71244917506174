import { App } from 'vue'
import { tableWrapper } from './table'
import { seoText } from './seoText'
import { accordion } from './accordion'

const register = (app: App<Element>): void => {
  app.directive('table-wrapper', tableWrapper)
  app.directive('seo-text', seoText)
  app.directive('accordion', accordion)
}

export default {
  register,
}
