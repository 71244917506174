export const seoText = {
  mounted: (node: HTMLElement): any => {
    setTimeout(() => {
      const content = node.querySelector('.js-seo-text-content') as HTMLElement
      const triggerMore = node.querySelector('.js-seo-text-more') as HTMLElement
      const triggerLess = node.querySelector('.js-seo-text-less') as HTMLElement

      if (!triggerMore || !content) return

      if (content.clientHeight < 120) {
        triggerMore.classList.add('is-visible')
      }

      triggerMore.addEventListener('click', () => {
        content.classList.add('is-opened')
        triggerMore.classList.remove('is-visible')
        triggerLess.classList.add('is-visible')
      })

      triggerLess.addEventListener('click', () => {
        content.classList.remove('is-opened')
        triggerLess.classList.remove('is-visible')
        triggerMore.classList.add('is-visible')
      })
    }, 100)
  }
}
