import { optionsT } from '@/router'

import { createRoutes as createSettingsRoutes } from '@components/Profile/modules/settings/router'
import { createRoutes as createInsuranceCasesRoutes } from '@components/Profile/modules/insurance-cases/router'
import { createRoutes as createInsuranceObjectsRoutes } from '@components/Profile/modules/insurance-objects/router'
import { createRoutes as createLegalEntityRoutes } from '@components/Profile/modules/legal-entity/router'
import { createRoutes as createPoliciesRoutes } from '@components/Profile/modules/policies/router'
import { createRoutes as createErrorsRoutes } from '@components/Profile/modules/errors/router'

export function createRoutes(options: optionsT): any {
  return [
    ...createSettingsRoutes(options),
    ...createInsuranceCasesRoutes(options),
    ...createInsuranceObjectsRoutes(options),
    ...createLegalEntityRoutes(options),
    ...createPoliciesRoutes(options),
    ...createErrorsRoutes(options),
  ]
}
