import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-control-input" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_eyes_off = _resolveComponent("icon-eyes-off")!
  const _component_icon_eyes_on = _resolveComponent("icon-eyes-on")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.isPasswordField)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "d-control-input__icon d-control-input__icon--append",
          onClick: _withModifiers($setup.toggleType, ["prevent"])
        }, [
          ($setup.passwordIsVisible)
            ? (_openBlock(), _createBlock(_component_icon_eyes_off, {
                key: 0,
                class: "eyes-off"
              }))
            : (_openBlock(), _createBlock(_component_icon_eyes_on, {
                key: 1,
                class: "eyes-on"
              }))
        ], 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("input", _mergeProps({
      class: ["d-control-input__element", { 'd-control-input__element--icon_append': $setup.isPasswordField }]
    }, _ctx.$attrs, { type: $setup.inputType }), null, 16, _hoisted_3)
  ]))
}