import { App } from 'vue'

import Controls from './Controls'
import Modals from './Modals'
import Ui from './Ui'
import Sliders from './Sliders'
import List from './List'
import Profile from './Profile'
import Authentication from './Authentication'
import Forms from './Forms'
import News from './News'
import Reviews from './Reviews'

const install = (app: App<Element>): void => {
  Controls.register(app)
  Modals.register(app)
  Ui.register(app)
  Sliders.register(app)
  List.register(app)
  Profile.register(app)
  Authentication.register(app)
  Forms.register(app)
  News.register(app)
  Reviews.register(app)
}

export default {
  install,
}
