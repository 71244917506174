import { App } from 'vue'
import Login from './Login.vue'

const register = (app: App<Element>): void => {
  app
    .component('auth-login', Login)
}

export default {
  register,
}
