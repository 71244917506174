import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, {
    spaceBetween: 12,
    speed: 500,
    modules: $setup.modules,
    autoHeight: true,
    breakpoints: $setup.breakpoints,
    navigation: { nextEl: '.js-news-slider-next', prevEl: '.js-news-slider-prev' }
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["navigation"]))
}