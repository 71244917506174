import { App } from 'vue'

export const prettifyNumber = (p: string | number) => (p).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0')

export default {
  install(app: App<Element>): any {
    app.config.globalProperties.$filters = {
      prettifyNumber(a: string | number) {
        return prettifyNumber(a)
      },

      formatDecimal(a: string) {
        const preparedValue = Number(a)
        const numberWithPoint = parseFloat(a).toFixed(2)

        return Number.isInteger(preparedValue) ? preparedValue : numberWithPoint
      },
    }
  }
}
