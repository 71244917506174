import { App } from 'vue'
import { OnClickOutside } from '@vueuse/components'
import LanguageSwitch from './LanguageSwitch.vue'
import Dropdown from './Dropdown.vue'
import SidebarToggler from './SidebarToggler.vue'
import HeaderToggler from './HeaderToggler.vue'
import ClampText from './ClampText.vue'
import Cropper from './Cropper.vue'
import ScrollToTop from './ScrollToTop.vue'
import Share from './Share.vue'
import CopyButton from './CopyButton.vue'
import Recaptcha from './Recaptcha.vue'
import Percentage from './Percentage.vue'
import Document from './Document.vue'

const register = (app: App<Element>): void => {
  app
    .component('ui-click-outside', OnClickOutside)
    .component('ui-language-switch', LanguageSwitch)
    .component('ui-dropdown', Dropdown)
    .component('ui-sidebar-toggler', SidebarToggler)
    .component('ui-header-toggler', HeaderToggler)
    .component('ui-clamp-text', ClampText)
    .component('ui-cropper', Cropper)
    .component('ui-scroll-to-top', ScrollToTop)
    .component('ui-share', Share)
    .component('ui-copy-button', CopyButton)
    .component('ui-recaptcha', Recaptcha)
    .component('ui-percentage', Percentage)
    .component('ui-document', Document)
}

export default {
  register,
}
