import {
  createResource,
  patchResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'user/profile'

const USER_RETRIEVE = prefixAPI('/retrieve/', MODEL_PREFIX)
const USER_UPDATE = prefixAPI('/update/', MODEL_PREFIX)

export const userRetrieveResource = createResource(USER_RETRIEVE)
export const userUpdateResource = createResource(USER_UPDATE, patchResource)
