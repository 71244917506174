import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, {
    effect: "fade",
    modules: $setup.modules,
    autoHeight: true,
    loop: false,
    pagination: { clickable: true, el: '.js-intro-slider-pagination' },
    onSlideChange: $setup.onSlideChange,
    onSwiper: $setup.onSlideChange
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["pagination"]))
}