import { App } from 'vue'
import { defineScriptLoader, toQueryString, createPlugin } from 'vue-recaptcha'

export default {
  install(app: App<Element>): any {
    const loadScript = defineScriptLoader((options: any) => {
      const script = document.createElement('script')
  
      script.src = `${options.recaptchaApiURL}?${toQueryString(options.params)}`
      script.async = true
      script.defer = true
      if (options.nonce) {
        script.nonce = options.nonce
      }
      document.head.append(script)
    })
  
    const plugin = createPlugin(loadScript)

    app.use(plugin, {
      v2SiteKey: window.CONFIG.GOOGLE_RECAPTCHA_KEY,
    })
  }
}
