import { BASE_URL } from '@router/consts'
import useRoute from '@router/utils'
import { createRoutes } from '@components/Profile/router'

export type optionsT = { app: any, createInstance: any, createWebHistory: any, pinia: any }

const ViewComponent = () => import('@components/Profile/views/Profile.vue')

export default function createInitialRoutes(options: optionsT): any {
  return [
    useRoute(BASE_URL, ViewComponent, 'profile', {
      children: [
        ...createRoutes(options),
      ],
    }),
  ]
}