import { ref, inject } from 'vue'
import { until } from '@vueuse/core'
import { useErrorsGetter, useParsedSetter } from '@components/Forms/FormMixin'
import { loginResource } from '@services/authenticate.service'
import { BASE_URL as profileUrl } from '@router/consts'

interface Result {
  send: Promise<void | boolean>;
  isLoad: boolean;
}

interface SendData {
  recaptcha: string;
  username: string;
  password: string;
}

export default function (): Promise<Result> | any {
  const isLoad = ref<boolean>(false)
  const captchaConfig = inject('captchaConfig') as any

  const prepareUsername = (username: string, countryCode: string) => {
    if (!username.startsWith(countryCode)) {
      return `${countryCode}${username}`
    }

    return username
  }

  const send = async (sendData: SendData, control: any, countryCode: string): Promise<void | boolean> => {
    const formdata = JSON.parse(JSON.stringify(sendData))

    control.setFieldError('non_field_errors', '')

    if (isLoad.value) return false

    captchaConfig.execute()

    await until(captchaConfig.token).changed()

    captchaConfig.resetCaptcha()

    formdata.recaptcha = captchaConfig.token.value

    isLoad.value = true

    formdata.username = prepareUsername(formdata.username, countryCode)

    loginResource.execute({}, formdata).then(() => {
      const redirect = getRedirectUrl()
      const wind: Window = window

      if (redirect) {
        wind.location = redirect
      } else {
        wind.location = profileUrl
      }
    }).catch(async (e: object) => {
      const parsed = await useErrorsGetter(e)

      useParsedSetter(parsed, control.setFieldError)
    }).finally(() => {
      isLoad.value = false
    })
  }

  const getRedirectUrl = (): string | any => {
    const params = new URLSearchParams(window.location.search)
    const redirect = params.get('next')

    return redirect
  }

  return { send, isLoad, prepareUsername }
}