import { App } from 'vue'
import Intro from './Intro.vue'
import Advantages from './Advantages.vue'
import News from './News.vue'
import Reviews from './Reviews.vue'

const register = (app: App<Element>): void => {
  app
    .component('slider-intro', Intro)
    .component('slider-advantages', Advantages)
    .component('slider-news', News)
    .component('slider-reviews', Reviews)
}

export default {
  register,
}
