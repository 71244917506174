export function defaultDataTransformer(response: any) {
  return response.json().then((result: any) => result)
}

export function simpleTransformer(response: any) {
  return response
}

export function fetcher(resource: any, init = {}) {
  return fetch(resource, init)
}

export function transformDataFetcher(f: any, transformer: any) {
  return function fn() {
    /* eslint-disable prefer-rest-params */
    return f.apply(this, arguments).then(transformer)
  }
}

export function jsonTransformer(response: any) {
  return response.json()
}

export function raiseHttpErrorTransformer(response: any) {
  if (!response.ok) {
    throw response
  }
  return response
}