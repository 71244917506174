import { CreateAppFunction } from 'vue'
import { createWebHistory } from 'vue-router'
import { $vfm } from 'vue-final-modal'
import createRouter from '@router/index'
import Components from '@components/index'
import Modules from '@modules'
import Directives from '@directives'
import Icons from '@/icons'
import { useUserStore } from '@store/user'
import filters from '@utils/filters'

type CreateApplication = {
  createApp: CreateAppFunction<Element>
  createRouter?: any
  createWebHistory?: typeof createWebHistory
  createPinia?: any
}

export function createApplication(
  {
    createApp,
    createRouter: createInstance,
    createWebHistory,
    createPinia,
  }: CreateApplication
): any {
  const pinia = createPinia()

  const app = createApp({
    delimiters: ['[[', ']]'],

    data() {
      return {
        fromRouteName: {
          value: null,
        },
      }
    },

    provide() {
      return {
        fromRouteName: this.fromRouteName,
      }
    },

    watch: {
      $route(to, from) {
        this.fromRouteName.value = from.name
      },
    },

    async created() {
      this.showPartnerModal()

      if (window.isAuthenticated) {
        const userStore = useUserStore()

        userStore.getUserData()
      }
    },

    methods: {
      showPartnerModal() {
        const params = new URLSearchParams(document.location.search)
        const partner = params.get('feeds-partner')

        if (!partner) return

        $vfm.show({
          component: 'UiModalContainerPreventClose',
        }, {
          classes: 'modal--size_lg',
          component: 'Modals/Common/FeedbackPartner',
        })
      },
    },
  })

  const router = createRouter({ app, createInstance, createWebHistory, pinia })

  app.use(pinia)
  app.use(Modules)
  app.use(router)
  app.use(filters)

  Components.install(app)
  Directives.register(app)
  Icons.register(app)

  app.mount('#app')

  return app
}
