import {
  createResource,
  postResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'

const FEEDBACK_CREATE = prefixAPI('/create/', 'feedback')
const FEEDBACK_PARTNER_CREATE = prefixAPI('/create/', 'partner-feedback')

export const feedbackCreateResource = createResource(FEEDBACK_CREATE, postResource)
export const feedbackPartnerCreateResource = createResource(FEEDBACK_PARTNER_CREATE, postResource)
