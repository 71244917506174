import { optionsT } from '@/router'
import useRoute from '@router/utils'
import { LIST_URL, DETAILS_URL } from './consts'

const List = () => import('./views/List.vue')
const Details = () => import('./views/Details.vue')

export const LIST = 'profile:legal-entity:list'
export const DETAILS = 'profile:legal-entity:details'

export function createRoutes(options: optionsT): any {
  return [
    useRoute(LIST_URL, List, LIST, {}),
    useRoute(DETAILS_URL, Details, DETAILS, { props: true }),
  ]
}
