export const baseOrder = {
  path: [
    'prefix',
    'label',
    'postfix',
    'page',
    'filters',
  ],
}

export const queryOrder = {
  path: [
    'prefix',
    'label',
    'postfix',
  ],
  query: [
    'query',
  ],
}