import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-control-input" }
const _hoisted_2 = { class: "d-control-input__select" }
const _hoisted_3 = { class: "phone-code" }
const _hoisted_4 = { class: "phone-code__icon-wrapper" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "phone-code__text" }
const _hoisted_7 = { class: "phone-code" }
const _hoisted_8 = { class: "phone-code__icon-wrapper" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "phone-code__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_select = _resolveComponent("control-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_control_select, {
        modelValue: $setup.formdata.code,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formdata.code) = $event)),
        "open-direction": $setup.props.openDirection,
        "max-height": $setup.props.maxHeight,
        name: "code",
        "track-by": "id",
        "allow-empty": false,
        searchable: false,
        options: $setup.props.codes,
        onSelect: $setup.codeChanged
      }, {
        singleLabel: _withCtx(({ option }) => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                class: "phone-code__icon",
                src: option.icon
              }, null, 8, _hoisted_5)
            ]),
            _createElementVNode("div", _hoisted_6, _toDisplayString(option.code), 1)
          ])
        ]),
        option: _withCtx(({ option }) => [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("img", {
                class: "phone-code__icon",
                src: option.icon
              }, null, 8, _hoisted_9)
            ]),
            _createElementVNode("div", _hoisted_10, _toDisplayString(option.code), 1)
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "open-direction", "max-height", "options"])
    ]),
    _withDirectives(_createElementVNode("input", _mergeProps({ class: "d-control-input__element d-control-input__element--variant_select" }, _ctx.$attrs, {
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.model) = $event)),
      type: "tel"
    }), null, 16), [
      [_vModelText, $setup.model]
    ])
  ]))
}