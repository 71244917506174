<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_503_1274)">
      <path d="M7.41144 7.40991C7.19038 7.6159 7.01307 7.8643 6.89009 8.1403C6.76712 8.4163 6.70099 8.71424 6.69566 9.01634C6.69033 9.31845 6.74591 9.61854 6.85907 9.8987C6.97223 10.1789 7.14067 10.4334 7.35432 10.647C7.56798 10.8607 7.82248 11.0291 8.10265 11.1423C8.38281 11.2554 8.6829 11.311 8.98501 11.3057C9.28711 11.3004 9.58505 11.2342 9.86105 11.1113C10.137 10.9883 10.3855 10.811 10.5914 10.5899" stroke="#454545" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.04688 3.81C8.36286 3.77063 8.68095 3.75059 8.99938 3.75C14.2494 3.75 16.4994 9 16.4994 9C16.1641 9.71784 15.7435 10.3927 15.2469 11.01" stroke="#454545" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.9575 4.95752C3.46594 5.97349 2.2724 7.36896 1.5 9.00002C1.5 9.00002 3.75 14.25 9 14.25C10.4369 14.2539 11.8431 13.8339 13.0425 13.0425" stroke="#454545" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1.5 1.5L16.5 16.5" stroke="#454545" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_503_1274">
      <rect width="18" height="18" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>