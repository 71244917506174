import {
  createResource,
  baseResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'
import { friendlyUrlGenerator } from '@utils/friendly'
import { queryOrder } from '@services/order'

const MODEL_PREFIX = 'user/profile/policy'

const POLICY_RETRIEVE = prefixAPI('/retrieve/{id}/', MODEL_PREFIX)
const POLICY_FITLERS = prefixAPI('/filters/', MODEL_PREFIX)
const POLICY_LIST_EXPIRING = prefixAPI('/list/expiring/', MODEL_PREFIX)
export const POLICY_LIST = prefixAPI('/list/', MODEL_PREFIX)

const FILTERS_LIST_URL_PREFIX = prefixAPI('/list', MODEL_PREFIX)
const FILTERS_LIST_URL_POSTFIX = ''

export const policyListResource = friendlyUrlGenerator([
  FILTERS_LIST_URL_PREFIX, FILTERS_LIST_URL_POSTFIX
], baseResource, queryOrder)
export const policyRetrieveResource = createResource(POLICY_RETRIEVE)
export const policyFiltersResource = createResource(POLICY_FITLERS)
export const policyListExpiringResource = createResource(POLICY_LIST_EXPIRING)
