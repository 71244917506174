import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_close = _resolveComponent("icon-close")!
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createBlock(_component_vue_final_modal, null, {
    default: _withCtx(({ close, params }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["modal", params ? params.classes : ''])
      }, [
        _createElementVNode("div", {
          class: "close",
          onClick: ($event: any) => ($setup.checkHandler(params, close))
        }, [
          _createVNode(_component_icon_close)
        ], 8, _hoisted_1),
        (_openBlock(), _createBlock(_resolveDynamicComponent($setup.componentExtactor), {
          params: params,
          close: close,
          "component-path": $setup.local,
          "onHook:created": ($event: any) => ($setup.save(params))
        }, null, 40, ["params", "close", "component-path", "onHook:created"]))
      ], 2)
    ]),
    _: 1
  }))
}