import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_base_view = _resolveComponent("list-base-view")!

  return (_openBlock(), _createBlock(_component_list_base_view, {
    initial: $setup.props.initial,
    "base-url": $setup.props.baseUrl,
    "list-resource": $setup.newsCatalogResource
  }, {
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "content")
    ]),
    _: 3
  }, 8, ["initial", "base-url", "list-resource"]))
}