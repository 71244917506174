import useRoute from '@router/utils'
import { optionsT } from '@/router'

const NotFound = () => import('./views/404.vue')

export const ROUTE_NAME = 'profile:error:404'

export function createRoutes(options: optionsT): any {
  return [
    useRoute('/:pathMatch(.*)*', NotFound, ROUTE_NAME, {}),
  ]
}
