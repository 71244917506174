import {
  baseResource,
} from '@resource/resource'
import { prefixLanguage } from '@utils/urls'
import { friendlyUrlGenerator } from '@utils/friendly'

const FILTERS_LIST_URL_PREFIX = prefixLanguage('/reviews/')
const FILTERS_LIST_URL_POSTFIX = 'ajax'

export const reviewsCatalogResource = friendlyUrlGenerator([
  FILTERS_LIST_URL_PREFIX, FILTERS_LIST_URL_POSTFIX
], baseResource)
