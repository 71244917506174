import { accessGuard } from '@aspectus/vue-router-permissions'
import createInitialRoutes from '@router/routes'

export type optionsT = { app: any, createInstance: any, createWebHistory: any, pinia: any }

export function createRoutes(options: optionsT): any[] {
  return [
    ...createInitialRoutes(options),
  ]
}

export function scrollBehavior() {
  return new Promise(resolve => {
    setTimeout(() => {
      const app = document.getElementById('app')

      if (!app) return

      resolve(app.scrollIntoView({ behavior: 'smooth' }))
    }, 200)
  })
}

export default function createRouter(options: optionsT): any {
  const { createInstance, createWebHistory } = options
  const router = createInstance({
    history: createWebHistory(),
    scrollBehavior,
    routes: createRoutes(options),
  })

  router.beforeEach(accessGuard(options))

  return router
}
