import { pipe, partialRight } from 'ramda'
import {
  baseResource as superResource,
  receiveResource,
  jsonRequestMiddleware,
} from '@aspectus/resource'

import makeTemplateGetter from '@aspectus/resource-template-url-getter'
import {
  fetcher,
  defaultDataTransformer,
  transformDataFetcher,
  raiseHttpErrorTransformer,
} from '@resource/transformer'

import { HEADERS_MIDDLEWERE } from '@resource/middleware'

export const getResource = receiveResource
  .middleware(jsonRequestMiddleware)
  .middleware(HEADERS_MIDDLEWERE)

export const postResource = getResource
  .config('method', 'POST')

export const patchResource = postResource
  .config('method', 'PATCH')

export const putResource = postResource
  .config('method', 'PUT')

export const deleteResource = getResource
  .config('method', 'DELETE')

export const optionsResource = getResource
  .config('method', 'OPTIONS')

export const baseResource = superResource
  .middleware(HEADERS_MIDDLEWERE)
  .transform(raiseHttpErrorTransformer)

export function createResource(
  template: any,
  resource = getResource,
  transformer = defaultDataTransformer
) {
  return resource
    .url(makeTemplateGetter(template))
    .fetcher(transformDataFetcher(fetcher,
      pipe(raiseHttpErrorTransformer, transformer)))
}

export const simpleFetcher = async (url: string) => {
  const headers = new Headers()

  headers.append('credentials', 'include')
  headers.append('Access-Control-Allow-Credentials', 'true')

  const response = await fetch(url, { headers })

  const data = await response.json()

  return data
}

export const postFetcher = async (url: string, formdata: object, params: object) => {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(formdata),
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
    ...params,
  })

  const data = await response.json()

  return data
}
export const createReceiver = partialRight(createResource, [receiveResource])
