import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_select = _resolveComponent("control-select")!

  return (_openBlock(), _createBlock(_component_control_select, {
    modelValue: $setup.model,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
      $setup.debounceUpdate
    ],
    name: $setup.props.filter.name,
    "track-by": $setup.props.filter.meta ? ($setup.props.filter.meta.value || 'value') : 'value',
    multiple: $setup.props.config.multiple || false,
    searchable: $setup.props.config.multiple || false,
    "allow-empty": true,
    options: $setup.props.filter.items,
    "input-label": $setup.props.filter.title,
    "option-label": $setup.props.filter.meta ? ($setup.props.filter.meta.title || 'title') : 'title',
    placeholder: $setup.props.placeholder || '',
    class: _normalizeClass($props.inputClass)
  }, null, 8, ["modelValue", "name", "track-by", "multiple", "searchable", "options", "input-label", "option-label", "placeholder", "class", "onUpdate:modelValue"]))
}