import useGlobal from '@/composables/useGlobal'

export default function () {
  const { $t } = useGlobal()

  const languages = [
    {
      value: 'uk',
      label: $t('Ukrainian'),
      shortTitle: $t('UK'),
    },
    {
      value: 'ru',
      label: $t('Russian'),
      shortTitle: $t('RU'),
    },
    {
      value: 'en',
      label: $t('English'),
      shortTitle: $t('EN'),
    },
    {
      value: 'tr',
      label: $t('Turkish'),
      shortTitle: $t('TR'),
    },
  ]

  const networksForShare = [
    {
      network: 'facebook',
      title: $t('Facebook'),
      icon: 'icon-facebook',
      color: '#3b5998',
    },
    {
      network: 'telegram',
      title: $t('Telegram'),
      icon: 'icon-telegram',
      color: '#0088cc',
    },
    {
      network: 'viber',
      title: $t('Viber'),
      icon: 'icon-viber',
      color: '#7360f2',
    },
    {
      network: 'twitter',
      title: $t('Twitter'),
      icon: 'icon-twitter',
      color: '#1da1f2',
    },
    {
      network: 'linkedin',
      title: $t('Linkedin'),
      icon: 'icon-linkedin',
      color: '#0a66c2',
    },
    {
      network: 'whatsapp',
      title: $t('Whatsapp'),
      icon: 'icon-whatsapp',
      color: '#25d366',
    },
  ]

  return {
    languages,
    networksForShare,
  }
}