import qs from 'qs'

/**
 * @param {array} files
 */
export const normalizeFiles = files => {
  const arr = files.reduce((acc, el) => {
    const data = `${el.name};${el.src}`

    acc.push(data)

    return acc
  }, [])

  return arr
}

/**
 * @param {string} file
 */
export const getFileName = file => {
  if (file) {
    const one = 1
    const lastIndexOf = file.lastIndexOf('/') + one

    return {
      src: file,
      name: file.substr(lastIndexOf),
    }
  }

  return null
}

export const alphabetSorting = (items, key) => items.sort((a, b) => a[key].localeCompare(b[key]))

export const clearPhone = phone => phone.replace(/[^+0-9]/g, '')

export const prettifyPhone = phone => {
  const digits = phone.replace(/\D/g, '')
  const prittyfied = digits.replace(/(\w{2})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5')

  return prittyfied
}

export function parsePaginationUrl() {
  const { search } = window.location

  if (search.includes('limit') || search.includes('offset')) {
    const limit = search.match(/limit=+(\d+)/)
    const offset = search.match(/offset=+(\d+)/)

    return {
      limit: limit ? Number(limit[1]) : null,
      offset: offset ? Number(offset[1]) : null,
    }
  }
  return {}
}

/**
 * @param {string} url
 */
export const queryStringParser = url => {
  /**
   * Parse url for creating an object of selected attributes
   * `ignoreQueryPrefix` - this option removes the `?` character from url
   */
  const parsed = qs.parse(url, { ignoreQueryPrefix: true, comma: true })

  const prettified = Object.keys(parsed).reduce((acc, key) => {
    if ('limit' !== key && 'offset' !== key) {
      acc[key] = parsed[key]
    }
    return acc
  }, {})

  return prettified
}