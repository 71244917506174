<template>
  <render />
</template>

<script setup>
import { useSlots } from 'vue'
import { copyText } from 'vue3-clipboard'
import { useNotification } from '@kyvg/vue3-notification'
import useGlobal from '@composables/useGlobal'

const props = defineProps({
  text: {
    type: String,
  },
})
const slots = useSlots()
const { $t } = useGlobal()
const { notify } = useNotification()

const doCopy = () => {
  copyText(props.text, undefined, (error, event) => {
    if (error) {
      notify({ title: $t("Не вдалося скопіювати"), type: 'danger' })
    } else {
      notify({ title: $t("Скопійовано"), type: 'success' })
    }
  })
}

const render = () => slots.default({
  doCopy,
})
</script>